import { APP_CONFIGS, APP_VERSION } from '@flowardco/flib-helpers';
import { datadogLogs } from '@datadog/browser-logs';
import type { AxiosError } from 'axios';

const isEnabled = APP_CONFIGS.datadog.logs.enabled;
const clientToken = APP_CONFIGS.datadog.logs.clientToken || '';

export type DatadogErrorIdentifiersPayload = {
  orderId?: any;
  productId?: any;
  recommendedProductId?: number;
  cartItemId?: any;
};

export type DatadogErrorOptionalPayload = {
  source?: string | null;
  metaData?: any | null;
  identifiers?: DatadogErrorIdentifiersPayload;
  reqBody?: any;
};

export interface DatadogErrorPayload {
  apiRequest?: any;
  apiResponse?: any;
  url?: string;
  message?: string;
  headers?: any;
  statusCode?: number;
  source?: string;
  metaData?: any;
  identifiers?: DatadogErrorIdentifiersPayload;
}

export const traceClientLogs = (service: string, options?: any) => {
  if (isEnabled && clientToken) {
    datadogLogs.init({
      clientToken,
      site: 'datadoghq.eu',
      forwardErrorsToLogs: true,
      sampleRate: 100,
      service,
      env: APP_CONFIGS.datadog.logs.env,
      version: APP_VERSION,
      ...(options || {}),
    });
    datadogLogs.setLoggerGlobalContext({
      service,
      env: APP_CONFIGS.datadog.logs.env,
    });
  }
};

export const errorLogger = (msg: string, ctx: any): void => {
  try {
    if (isEnabled && clientToken && window['DD_LOGS']) {
      window['DD_LOGS'].logger.error(
        `${msg} - ${ctx?.response?.data?.message || ''}`,
        {
          responseInfo: ctx?.response?.data || '',
          ctx,
        }
      );
    }
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('Logger not found : errorLogger: ', msg, ctx, error);
  }
};

export const errorLoggerV2 = (
  message: string,
  apiError?: AxiosError,
  optionalPayload?: DatadogErrorOptionalPayload
): void => {
  try {
    if (isEnabled && clientToken && window['DD_LOGS']) {
      const errorDetails: any = {};
      const apiResponse = apiError?.response?.data || undefined;
      if (apiError?.response) {
        errorDetails.response = {
          responseData: apiResponse,
          status: apiError?.response?.status,
          headers: apiError?.response?.headers,
        };
      }
      if (apiError?.request) {
        errorDetails.request = apiError?.request;
      }
      if (apiError?.config) {
        errorDetails.config = apiError?.config;
      }
      if (apiError?.message) {
        errorDetails.message = apiError?.message;
      }
      const payload: DatadogErrorPayload = {
        url: window.location.href,
        apiResponse,
        apiRequest: {
          body: optionalPayload?.reqBody || undefined,
          url: apiError?.response?.request?.responseURL || undefined,
          method: apiError?.config?.method || undefined,
        },
        statusCode: apiError?.response?.status || undefined,
        headers: apiError?.config?.headers || undefined,
        identifiers: optionalPayload?.identifiers || undefined,
        metaData: {
          ...(optionalPayload?.metaData || {}),
          errorDetails,
        },
      };
      window['DD_LOGS'].logger.error(message, payload);
    }
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(
      'Logger not found : errorLogger: ',
      message,
      optionalPayload,
      error
    );
  }
};

export const ddLogger = (
  message: string,
  payload: any,
  type: 'debug' | 'info' | 'warn' | 'error' = 'info'
): void => {
  try {
    if (isEnabled && clientToken && window['DD_LOGS']) {
      window['DD_LOGS'].logger?.[type]?.(message, payload);
    }
  } catch (error: any) {
    // eslint-disable-next-line no-console
    console.error('Logger not found : ddLogger: ', message, payload, error);
  }
};
