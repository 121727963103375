import React from 'react';
import { LoadingSpinner } from '@flowardco/fui-loading-spinner';

export const FullPageLoader: React.FC = () => {
  return (
    <div className='fui-fixed fui-left-0 fui-top-0 fui-z-[1200] fui-flex fui-h-full fui-w-full fui-items-center fui-justify-center fui-bg-black-200 fui-bg-opacity-5'>
      <LoadingSpinner iconClassName='fui-h-12 fui-w-12' />
    </div>
  );
};

export const AppLoader: React.FC = () => {
  return (
    <div className='fui-flex fui-w-full fui-items-center fui-justify-center md:fui-min-h-[300px]'>
      <LoadingSpinner
        iconClassName='fui-h-7 fui-w-7 fui-text-salmon-900'
        logo={false}
      />
    </div>
  );
};

export const ProcessingSpinner: React.FC = () => (
  <div className='fui-absolute fui-left-0 fui-top-0 fui-z-20 fui-flex fui-h-full fui-w-full fui-items-center fui-justify-center fui-bg-white fui-bg-opacity-5'>
    <LoadingSpinner
      iconClassName='fui-h-7 fui-w-7 fui-text-salmon-900'
      logo={false}
    />
  </div>
);
