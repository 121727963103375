import React from 'react';
import cx from 'clsx';
export interface FieldErrorMessageProps {
  id?: string;
  errorMessage?: string;
  className?: string;
  testid?: string;
}

export const FieldErrorMessage: React.FC<FieldErrorMessageProps> = ({
  id,
  errorMessage,
  className,
  testid = 'TestId__InputField',
}) => {
  return (
    <label
      htmlFor={id}
      data-testid={`${testid}ErrorMessage`}
      className={cx('fui-block fui-w-full', className)}
    >
      {errorMessage && (
        <div
          className='fui-p-1 fui-text-sm fui-text-error-800'
          data-testid='TestId__FieldError'
        >
          {errorMessage}
        </div>
      )}
    </label>
  );
};
