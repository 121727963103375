import React from 'react';
import cx from 'clsx';
import styles from './Button.module.css';
import { LoadingSpinner } from '@flowardco/fui-loading-spinner';
export type TStylesClass = {
  [key: string]: string;
};

export type ButtonStyles =
  | 'primary'
  | 'secondary'
  | 'ternary'
  | 'outlinePrimary'
  | 'addToCart'
  | 'outlineSecondary'
  | 'outlineTernary'
  | 'primaryUnderline'
  | 'basicUnderline'
  | 'primaryText'
  | 'basicText';

export type ButtonSizes =
  | '2xs'
  | 'xs'
  | 'sm'
  | 'md'
  | 'base'
  | 'lg'
  | 'xl'
  | '2xl';

export type ButtonCases = 'lower' | 'upper' | 'normal';

export type ButtonRadius =
  | 'none'
  | 'sm'
  | 'default'
  | 'md'
  | 'lg'
  | 'xl'
  | '2xl'
  | '3xl'
  | 'full';

export interface ButtonProps extends React.ComponentProps<'button'> {
  label?: string | null;
  buttonStyle?: ButtonStyles;
  size?: ButtonSizes;
  rounded?: ButtonRadius;
  onClick?(event: React.MouseEvent<HTMLButtonElement>): void;
  overrideClass?: string | null;
  disabled?: boolean;
  cases?: ButtonCases;
  testid?: string;
  isProcessing?: boolean;
  children?: React.ReactNode;
}

export const Button: React.FC<ButtonProps> = ({
  children = null,
  label = null,
  className = '',
  type = 'button',
  buttonStyle = 'primary',
  size = 'md',
  rounded = 'none',
  overrideClass = null,
  disabled = false,
  cases = 'normal',
  testid = 'TestId__Button',
  isProcessing = false,
  ...buttonProps
}) => {
  const cssClass =
    overrideClass ??
    cx(
      styles.btn,
      styles[`btn-${buttonStyle}`],
      styles[`btn-size-${size}`],
      styles[`btn-rounded-${rounded}`],
      styles[`btn-cases-${cases}`],
      className
    );

  return (
    <button
      type={type}
      className={cssClass}
      disabled={disabled}
      {...buttonProps}
      data-testid={testid}
    >
      {isProcessing ? (
        <div
          className={cx(
            'fui-absolute fui-z-10 fui-flex fui-h-full fui-w-full fui-items-center fui-justify-center fui-overflow-hidden fui-bg-white'
          )}
        >
          <LoadingSpinner
            iconClassName={'fui-w-20px fui-h-20px'}
            logo={false}
          />
        </div>
      ) : null}
      {children || label}
    </button>
  );
};
