import React from 'react';
import { Toaster } from 'react-hot-toast';
import type {
  ToastPosition,
  ToastOptions,
  DefaultToastOptions,
} from 'react-hot-toast';

export interface ToastComponentProps {
  position?: ToastPosition;
  toastOptions?: ToastOptions;
}

export const ToastComponent: React.FC<ToastComponentProps> = ({
  position = 'top-right',
  toastOptions,
}) => {
  const defaultToastOptions: DefaultToastOptions = {
    duration: 3000,
    className: 'fui-p-1 fui-text-md',
    icon: null,
    success: {
      className:
        'fui-p-1 fui-w-full md:fui-min-w-[300px] !fui-bg-success-400 !fui-text-white',
    },
    error: {
      className:
        'fui-p-1 fui-w-full md:fui-min-w-[300px] !fui-bg-error-400 !fui-text-white',
    },
  };
  return (
    <Toaster
      position={position}
      data-testid='TestId__ToastComponent'
      toastOptions={{ ...defaultToastOptions, ...toastOptions }}
    />
  );
};
