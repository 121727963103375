import React, { type MouseEvent } from 'react';
import type { FallbackProps } from 'react-error-boundary';
import { ErrorBoundary } from 'react-error-boundary';

const logError = (error: Error, info: any) => {
  // Do something with the error, e.g. log to an external API
  // eslint-disable-next-line no-console
  console.error(error, info);
};

const ErrorFallback: React.FC<FallbackProps> = ({ error }) => {
  const handleRetry = (e: MouseEvent<HTMLButtonElement>) => {
    if (typeof window !== 'undefined' && window?.location) {
      e.preventDefault();
      window.location.reload();
    }
  };
  const showRefreshButton =
    error?.message?.includes('Failed to fetch dynamically imported module') ||
    false;

  return (
    <div
      className='fui-bg-error-400 fui-p-2 fui-text-sm fui-text-white'
      data-testid='TestId__AppErrorBoundary'
    >
      <p data-testid='TestId__ErrorFallbackTitle' className='fui-font-bold'>
        Something went wrong:
      </p>
      <pre data-testid='TestId__ErrorFallbackMessage'>{error?.message}</pre>
      {showRefreshButton ? (
        <>
          <button
            type='button'
            onClick={handleRetry}
            className='fui-relative fui-inline-flex fui-h-[38px] fui-items-center fui-justify-center fui-border fui-border-theme-navy-900 fui-bg-white fui-px-3.5 fui-text-center fui-text-md fui-text-theme-navy-900 fui-transition-colors fui-duration-200 hover:fui-bg-theme-navy-900 hover:fui-text-white focus:fui-border-transparent focus:fui-bg-white focus:fui-text-theme-navy-900 focus:fui-outline focus:fui-outline-4 focus:fui-outline-theme-navy-150 disabled:fui-cursor-not-allowed disabled:fui-border-theme-dimmed disabled:fui-bg-white disabled:fui-text-theme-surface-placeholder disabled:fui-outline-none'
          >
            Refresh and Retry
          </button>
        </>
      ) : (
        ''
      )}
    </div>
  );
};

export const AppErrorBoundary: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => (
  <ErrorBoundary FallbackComponent={ErrorFallback} onError={logError}>
    {children}
  </ErrorBoundary>
);
