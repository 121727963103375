import { Outlet, ScrollRestoration } from 'react-router-dom';

const RootLayoutPage = () => {
  return (
    <>
      <Outlet />
      <ScrollRestoration />
    </>
  );
};

export default RootLayoutPage;
