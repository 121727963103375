import React, { useEffect, useState } from 'react';
import cx from 'clsx';
import { FieldErrorMessage } from './FieldErrorMessage';
import { InputLabel } from './InputLabel';

export interface InputFieldProps extends React.ComponentProps<'input'> {
  inputFieldClass?: string;
  labelClass?: string;
  label?: string;
  errorMessage?: string;
  wrapperClassName?: string;
  required?: boolean;
  inputProps?: React.InputHTMLAttributes<HTMLInputElement>;
  setFormikFieldValue?: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => void;
  testId?: string;
  register?: any;
  errorMessageClass?: string;
  otherProps?: any;
}

export const InputField: React.FC<InputFieldProps> = ({
  onChange,
  onBlur,
  inputFieldClass,
  type = 'text',
  labelClass = '',
  label,
  required = false,
  id,
  name,
  disabled = false,
  errorMessage,
  placeholder,
  value = '',
  wrapperClassName = '',
  inputProps = {},
  setFormikFieldValue,
  testId = 'TestId__InputField',
  register,
  errorMessageClass,
  otherProps = {},
}) => {
  const [fieldValue, setFieldValue] = useState(value);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFieldValue(e.target.value);
    if (onChange) {
      onChange(e);
    }
  };

  useEffect(() => {
    setFieldValue(value);
    if (setFormikFieldValue) {
      setFormikFieldValue(name ?? '', value);
    }
  }, [value]);

  return (
    <div
      data-testid='TestId__InputFieldWrapper'
      className={cx('fui-block', wrapperClassName)}
    >
      <InputLabel
        id={`input-${id || name}`}
        labelClass={cx('fui-mb-1', labelClass)}
        label={label}
        required={required}
      />
      {register ? (
        <input
          data-testid={testId}
          className={cx(
            'fui-w-full fui-rounded fui-border fui-text-base fui-shadow-none fui-outline-none fui-ring-0 placeholder:fui-text-gray-600 focus:fui-border-navy-500 focus:fui-shadow-none focus:fui-outline-none focus:fui-ring-0',
            inputFieldClass,
            errorMessage ? 'fui-mb-0 fui-border-error-800' : 'fui-mb-2'
          )}
          placeholder={placeholder}
          type={type}
          name={name}
          disabled={disabled}
          id={`input-${id || name}`}
          {...register(id)}
          {...inputProps}
          {...otherProps}
          onBlur={onBlur}
        />
      ) : (
        <input
          data-testid={testId}
          className={cx(
            'fui-w-full fui-rounded fui-border fui-text-base fui-shadow-none fui-outline-none fui-ring-0 placeholder:fui-text-gray-600 focus:fui-border-navy-500 focus:fui-shadow-none focus:fui-outline-none focus:fui-ring-0',
            inputFieldClass,
            errorMessage ? 'fui-mb-0 fui-border-error-800' : 'fui-mb-2'
          )}
          placeholder={placeholder}
          type={type}
          name={name}
          disabled={disabled}
          value={fieldValue}
          onChange={handleChange}
          onBlur={onBlur}
          id={`input-${id || name}`}
          {...inputProps}
          {...otherProps}
        />
      )}
      <FieldErrorMessage
        id={`input-${id || name}`}
        errorMessage={errorMessage}
        className={cx(errorMessage ? 'fui-mb-2' : '', errorMessageClass)}
        testid={testId}
      />
    </div>
  );
};
