import React from 'react';
import cx from 'clsx';
export interface LoadingSpinnerProps {
  className?: string;
  overrideClass?: string;
  logo?: boolean;
  iconClassName?: string;
}

export const LoadingSpinner: React.FC<LoadingSpinnerProps> = ({
  className = '',
  overrideClass = '',
  logo = true,
  iconClassName = 'fui-w-50px fui-h-50px fui-m-4',
}) => {
  const cssClass = overrideClass || cx(className);

  return (
    <div className={cssClass} data-testid='TestId__LoadingSpinner'>
      <div
        className={cx('fui-rounded-xl  fui-p-5', logo ? 'fui-bg-white' : '')}
      >
        <img
          width={112}
          height={112}
          data-testid='TestId__LoadingSpinnerLogo'
          className={iconClassName}
          src='https://floward.imgix.net/web/images/loading-web.gif'
          alt='animated-logo'
        />
      </div>
    </div>
  );
};
