import React from 'react';
import cx from 'clsx';
import styles from './NoResult.module.css';

export type ContainerAlignment = 'start' | 'center' | 'end';

export interface NoResultProps {
  icon?: React.ReactNode;
  text: React.ReactNode | string;
  subText?: React.ReactNode | string;
  button?: React.ReactNode;
  alignment?: ContainerAlignment;
  className?: string;
  textClassName?: string;
  containerOverrideClass?: string;
}

export const NoResult: React.FC<NoResultProps> = ({
  icon,
  text,
  subText,
  button,
  alignment = 'center',
  className,
  textClassName,
  containerOverrideClass = null,
}) => {
  const containerClass =
    containerOverrideClass ??
    cx(
      'fui-flex fui-flex-col fui-w-full',
      styles[`container-${alignment}`],
      className
    );
  return (
    <div className={containerClass} data-testid='TestId__NoResult'>
      {icon ? <div data-testid='TestId__NoResultIcon'>{icon}</div> : ''}
      <h3
        className={cx(
          'fui-mt-2 fui-text-[1.75rem] sm:fui-mb-4  sm:fui-mt-4',
          styles[`text-align-${alignment}`],
          textClassName
        )}
        data-testid='TestId__NoResultText'
      >
        {text}
      </h3>
      <p data-testid='TestId__NoResultSubText' className='fui-mb-4 sm:fui-mb-8'>
        {subText}
      </p>
      {button ? <div data-testid='TestId__NoResultButton'>{button}</div> : ''}
    </div>
  );
};
