import { i18n } from '@flowardco/flib-i18n';
import { useParams } from 'react-router';
import translations from './translations.json';

export const useLocalTranslator = (): any => {
  const { lang = 'en' } = useParams();
  const language = lang?.toLowerCase() === 'ar' ? 'ar' : 'en';
  const { __T, __TK } = i18n(language, translations);
  return { __T, __TK };
};
