import React from 'react';
import cx from 'clsx';

export interface InputLabelProps {
  labelClass?: string;
  id?: string;
  label?: string | React.ReactNode;
  required?: boolean;
  count?: number;
  isFamilySelected?: boolean;
  labelTextClasses?: string;
}

export const InputLabel: React.FC<InputLabelProps> = ({
  id,
  labelClass = '',
  label = '',
  required = false,
  count = 0,
  isFamilySelected,
  labelTextClasses,
}) => {
  if (!label) return null;
  return (
    <label
      htmlFor={id}
      className={cx('fui-flex fui-items-center fui-text-md', labelClass)}
      data-testid='TestId__InputFieldLabel'
    >
      <span className={labelTextClasses}>{label}</span>
      {required && (
        <span
          data-testid='TestId__InputFieldRequired'
          className='fui-mx-1 fui-text-error-800'
        >
          *
        </span>
      )}
      {count > 0 && (
        <span
          data-testid='TestId__InputFieldCount'
          className={`fui-mx-2 fui-rounded ${
            isFamilySelected ? 'fui-bg-blue-200' : 'fui-bg-gray-250'
          } fui-px-2 fui-py-1 fui-text-md`}
        >
          {count}
        </span>
      )}
    </label>
  );
};
