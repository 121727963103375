import React from 'react';
import { useRouteError } from 'react-router-dom';
import { NoResult } from '@flowardco/fui-no-result';
import { useLocalTranslator } from '@/hooks/useLocalTranslator';
import { ButtonLink } from '@flowardco/fui-button';

export const RouteError = () => {
  const error: any = useRouteError();
  const { __T } = useLocalTranslator();
  return (
    <div>
      <NoResult
        className='fui-h-full fui-flex-1 !fui-justify-between'
        icon={<div className='fui-block fui-p-4' />}
        button={
          <ButtonLink
            label={__T('Shop Now')}
            href={'https://floward.com'}
            size='md'
            className='fui-mb-40 !fui-px-8 !fui-py-3'
          />
        }
        text={__T(error?.message || 'Something Went wrong')}
      />
    </div>
  );
};
