import React from 'react';
import cx from 'clsx';
import type {
  ButtonStyles,
  ButtonSizes,
  ButtonCases,
  ButtonRadius,
} from './Button';
import styles from './Button.module.css';

export interface ButtonLinkProps extends React.ComponentProps<'a'> {
  label?: string | null;
  buttonStyle?: ButtonStyles;
  size?: ButtonSizes;
  rounded?: ButtonRadius;
  onClick?(event: React.MouseEvent<HTMLAnchorElement>): void;
  overrideClass?: string | null;
  cases?: ButtonCases;
  testid?: string;
}

export const ButtonLink: React.FC<ButtonLinkProps> = ({
  children = null,
  label = null,
  className = '',
  buttonStyle = 'primary',
  size = 'md',
  rounded = 'none',
  href = '#',
  overrideClass = null,
  cases = 'normal',
  testid = 'TestId__ButtonLink',
  ...buttonProps
}) => {
  const cssClass =
    overrideClass ??
    cx(
      styles.btn,
      styles[`btn-${buttonStyle}`],
      styles[`btn-size-${size}`],
      styles[`btn-rounded-${rounded}`],
      styles[`btn-cases-${cases}`],
      className
    );

  return (
    <a href={href} className={cssClass} {...buttonProps} data-testid={testid}>
      {children || label}
    </a>
  );
};
